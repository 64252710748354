.container {
  @apply flex justify-center pt-3 pb-4.5 large:pt-10 large:pb-7;
}

.chanceBar {
  @apply border border-transparent flex items-center rounded-full large:h-11 text-small large:text-large large:w-[400px];
  background-color: #ffffff;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: 1px solid #ee4d2d;
}

.rewardIconContainer {
  @apply flex justify-center items-center bg-primary rounded-full -m-px mr-3.5 large:mr-12 w-7.5 h-7.5 large:w-11 large:h-11 relative;
  background-image: linear-gradient(#ee4d2d, #ee4d2d), linear-gradient(#d0011b, #ffbd34);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.rewardIcon {
  @apply w-5 h-5 large:w-7 large:h-7;
}

.actionBtn {
  @apply text-primary font-medium py-1.5 disabled:text-gray-500 focus:outline-none ml-1 mr-4;
}

.content {
  @apply flex justify-center items-center;
}
